// Import global vars
@import "master";
// @import "fonts";

html,
body {
  height: 100%;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  // font-family: "Open Sans", sans-serif;
  font-family: "Rubik", sans-serif;

  background: #000;
  color: #fff;
}
.main-content {
  flex: 1 0 auto;
}
.main-footer {
  flex-shrink: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

// Checkbox
.form-checkbox-wrap {
  position: relative;
  padding-left: 1.75em;
  margin-bottom: 12px;

  .custom-control-label {
    // font-style: normal;
    // font-weight: normal;
    // font-size: 14px;
    // line-height: 20px;
    // color: #b0b0b0;

    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
    // span {
    //   margin-top: 6px;
    // }

    &::before {
      background: #fff;
      border-color: rgba(0, 0, 0, 0.1) !important;
      border-radius: 3px !important;
      box-shadow: none !important;
      width: 17px !important;
      height: 17px !important;
    }
    &::after {
      width: 17px !important;
      height: 17px !important;
    }

    span {
      display: inline-block;
      margin-top: 4px;
      margin-left: 6px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    // color: #fff;
    // background-color: #128ad6;
    background-color: #ffffff;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xNS43NjU3IDIuMzU5MjRDMTUuNDUzMyAyLjA0NjggMTQuOTQ2OCAyLjA0NjggMTQuNjM0MyAyLjM1OTI0TDUuMDQ5ODEgMTEuOTQzOEwxLjM2NTY5IDguMjU5NzFDMS4wNTMyOCA3Ljk0NzI3IDAuNTQ2NzgyIDcuOTQ3MyAwLjIzNDMxMyA4LjI1OTcxQy0wLjA3ODEyNDYgOC41NzIxMSAtMC4wNzgxMjQ2IDkuMDc4NjEgMC4yMzQzMTMgOS4zOTEwNUw0LjQ4NDEzIDEzLjY0MDhDNC43OTY0NCAxMy45NTMyIDUuMzAzMzEgMTMuOTUzIDUuNjE1NSAxMy42NDA4TDE1Ljc2NTcgMy40OTA2MUMxNi4wNzgxIDMuMTc4MjEgMTYuMDc4MSAyLjY3MTY3IDE1Ljc2NTcgMi4zNTkyNFoiIGZpbGw9IiNmZmYiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K") !important;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzQgNy44NzI5Nkw1LjUgMTMuNTJMMTQuMzQgMS41MjAwMiIgc3Ryb2tlPSIjMUU1RURBIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") !important;
    background-position: 2px 2px;
    background-size: 14px 14px;
    margin-top: -4px;
    margin-left: 3px;
  }
}
// Input
.dd-input-wrap {
  position: relative;
  margin-bottom: 20px;

  &--after {
    .dd-input {
      padding-right: 50px;
    }
  }
}
.dd-input {
  color: #ffffff;
  border: 2px solid #ffffff;
  font-size: 20px;
  font-weight: 500;
  // height: 50px;
  padding: 12px 20px 10px 20px !important;
  background-color: transparent !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  line-height: 1.33;
  width: 100%;
  -webkit-appearance: none;
  outline: none !important;
}
.dd-select-wrap {
  position: relative;

  &::after {
    content: "▼";
    position: absolute;
    font-size: 1rem;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    pointer-events: none;
    color: rgba(0, 0, 0, 0.6);
  }
}
.dd-select {
  appearance: none;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 1rem;
  line-height: 140%;
  outline: none !important;

  padding-right: 36px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}
.dd-label {
  display: block;
  color: #ffffff;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 5px;
}
// Button
.dd-button {
  padding: 14px 60px;

  font-size: 1rem;
  // line-height: 150%;
  font-weight: 700;
  min-height: 60px;

  cursor: pointer;

  background: $primaryColor;
  box-shadow: 0px 8px 16px rgba(0, 90, 159, 0.2);
  color: #fff !important;
  border-radius: 5px;
  text-decoration: none !important;
  outline: none !important;
  border: 2px solid $primaryColor;
  // display: inline-block;
  display: inline-flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;

  transition: 150ms;
  &:hover {
    opacity: 0.9;
  }

  &--small {
    padding: 10px 20px !important;
  }
  &--unfilled {
    background: transparent;
    box-shadow: none;
    border: 2px solid #fff;

    &:hover {
      background: #fff;
      color: #000 !important;

      a {
        color: #000 !important;
      }
    }
  }
  &--unfilled-primary {
    background: transparent;
    border: 2px solid $accentColor;
    color: $accentColor !important;
    box-shadow: none;

    &:hover {
      background: $accentColor;
      box-shadow: 0px 8px 16px rgba(0, 90, 159, 0.2);
      color: #fff !important;
    }
  }
}

.contact-form {
  &__agreement {
    margin-top: 15px;
    font-size: 18px;
    text-align: left;
    font-weight: 700;
    color: rgb(214, 214, 214) !important;

    a {
      color: rgb(214, 214, 214) !important;
      text-decoration: none;
      border-bottom: 1px solid rgb(214, 214, 214);
      box-shadow: inset 0px 0px 0px 0px rgb(214 214 214);
    }
  }
}

.main-header {
  z-index: 5;
  position: relative;
  margin-bottom: -87px;

  nav {
    // background-color: #000 !important;
    background: transparent !important;
    color: #fff;
    padding: 4px 30px;
  }
  .navbar-nav {
    &.navbar-main {
      .nav-item {
        padding: 0 17px;
        &:first-child {
          padding-left: 0;
          .nav-link {
          }
        }
        &:last-child {
          padding-right: 0;
          .nav-link {
          }
        }
      }
      .nav-link {
        color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        padding: 0;

        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #fff;
          opacity: 0;
          pointer-events: none;

          transition: 300ms;
        }

        &:hover {
          &::after {
            bottom: 2px;
            opacity: 1;
          }
        }

        &.active {
          opacity: 0.7;
          &::after {
            bottom: 2px;
            opacity: 1;
          }
        }
      }
    }

    &.navbar-socials {
      padding-left: 30px;
      .nav-item {
        padding: 0 5px;
      }
      .nav-link {
        color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        padding: 0;
      }
    }
  }
  &__logo {
    width: 118px;
  }

  &.sticky {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: -100px;
    transition: 200ms;
    &.animated {
      top: 0;
    }
  }
  &--dark,
  &--sticky-dark {
    margin-bottom: 0;
    nav {
      background: #000 !important;
      color: #fff;
    }

    // .main-header__logo-dark {
    //   display: block;
    // }
    // .main-header__logo {
    //   display: none;
    // }
    // .main-header__burger-btn {
    //   color: rgba(0, 0, 0, 0.4);
    // }
    // .navbar-nav {
    //   .nav-item {
    //     color: #000;
    //     .nav-link {
    //       color: #000;
    //     }
    //   }
    // }
    // .main-header__language-menu {
    //   &-link {
    //     color: #000;
    //   }
    // }
    // .main-header__call-btn {
    //   background: transparent;
    //   border: 1px solid $accentColor;
    //   color: $accentColor !important;
    //   box-shadow: none;

    //   &:hover {
    //     background: $accentColor;
    //     box-shadow: 0px 8px 16px rgba(0, 90, 159, 0.2);
    //     color: #fff !important;
    //   }
    // }

    // .navbar-toggler {
    //   color: rgba(0, 0, 0, 0.4);
    // }
  }
}
.main-footer {
  padding-top: 75px;
  padding-bottom: 60px;
  &__logo {
    width: 100px;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    color: #ffc33f;
    font-weight: 700;
  }
  &__section-title {
    color: rgb(184, 184, 184);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }
  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    &-item {
      font-size: 26px;
      line-height: 34px;
      font-weight: 500;
      &--small {
        font-size: 22px;
      }
    }
    &-link {
      color: inherit !important;
      text-decoration: none;
    }
  }
}
.copyright {
  margin-top: 20px;
  color: rgb(182, 182, 182);
  margin-bottom: 1rem;
  &__big {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
  }
  &__main {
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
  }
}
.legal-links {
  font-weight: 600;
  color: rgb(184, 184, 184);
  font-size: 14px;
  line-height: 130%;

  a {
    color: rgb(117, 117, 117) !important;
    text-decoration: none;
    border-bottom: 1px solid rgb(117, 117, 117);
    box-shadow: inset 0px 0px 0px 0px rgb(117 117 117);
  }
}

.section {
  padding: 90px 0;

  &--accent {
    background: $accentColor;
  }
  &--white {
    background: #fff;
    color: rgb(56, 56, 56);
  }
  &--big {
    padding: 150px 0;
  }
}
.section-title {
  color: $primaryColor;
  font-size: 90px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;

  &--white {
    color: #fff;
  }

  &__separator {
    font-size: 90px;
    font-weight: 600;
  }
}
.section-text {
  font-size: 26px;
  line-height: 1.3;
  font-weight: 400;

  &--limited {
    max-width: 720px;
    margin: auto;
  }
}
.section-small-text {
  font-size: 22px;
  line-height: 30px;
  &--dark {
    color: #383838;
  }
}

.sticky-parallax-text {
  text-align: center;
  color: #242424;
  position: sticky;
  top: 150px;
}
.sticky-overtext {
  position: relative;
  z-index: 5;
  padding-top: 120px;
  padding-bottom: 120px;
}

.hero-section {
  position: relative;

  height: 700px;
  width: 100%;
  background-size: cover;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  overflow: hidden;

  height: 100vh;
  background-image: url("../assets/1.png");

  padding: 0;

  &__overlay-filter {
    height: 100vh;
    background-color: #000;
    filter: alpha(opacity=40);
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &__iframe-wrap {
    position: relative;
    z-index: 1;

    height: 100vh;
    background-attachment: scroll;
    background-color: rgb(0, 0, 0);
  }
  &__iframe {
    // width: 100%;
    // height: 100%;
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 120%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    color: #fff;
  }

  &__content-inner {
    margin-left: 10px;
  }
  &__title {
    font-size: 52px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: 1px;
  }
  &__separator {
    color: $primaryColor;
    font-size: 52px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__text {
    padding: 0 0 30px 0;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 400;
    max-width: 650px;
  }
  &__controls {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }
  &__control-wrap {
    padding: 0 8px;
    margin-bottom: 10px;
  }
}

.benefits-item {
  margin-bottom: 120px;

  &__subtitle {
    color: $primaryColor;
    font-size: 40px;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: 1px;
  }
  &__title {
    color: $primaryColor;
    font-size: 72px;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  &__text {
    color: #ffffff;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 400;
  }
  &__hr {
    height: 2px;
    opacity: 1;
    margin: 0;
    margin-top: 30px;
  }
}

.client-item {
  display: flex;
  margin-bottom: 60px;
  align-items: center;
  &__img-wrap {
    flex: 0 0 auto;
    width: 112.5px;
    margin-right: 35px;
  }
  &__img {
    width: 100%;
  }
  &__content {
    font-size: 22px;
    line-height: 30px;
    color: #383838;
    p {
      margin-bottom: 0;
    }
  }
}

.field-section {
  &__text {
    padding-left: 150px;
    padding-top: 35px;
  }
}

.field-item {
  margin-bottom: 80px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  &__img-wrap {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title {
    font-size: 30px;
    font-weight: 600;
  }
  &__content {
    font-size: 18px;
    font-weight: 400;
  }
}

.accordion-title {
  font-size: 40px;
  line-height: 48px;
  color: $primaryColor;
  font-weight: 700;
  line-height: 1.35;
}
.accordion-subtitle {
  font-size: 20px;
  color: rgb(163, 163, 163);
  line-height: 48px;
}
.accordion-header__content {
  margin-right: 15px;
}
.accordion-item {
  border: none;
  padding-left: 10px;
  padding-right: 10px;
}
.accordion-button {
  box-shadow: none !important;
  outline: none !important;
  &::after {
    display: none;
  }
  &:hover {
    .accordion-icon {
      g {
        stroke: $primaryColor;
      }
    }
  }
}
.accordion-button:not(.collapsed) {
  color: rgb(56, 56, 56);
  background-color: #fff;
}
.accordion-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
  transition: 0.2s ease-in-out;
  g {
    transition: 0.2s ease-in-out;
  }
}
.accordion-button:not(.collapsed) {
  .accordion-icon {
    transform: rotate(-45deg);
    g {
      stroke: $primaryColor;
    }
  }
}
.accordion-body {
  padding-bottom: 30px;
}
.accordion-content {
  &__title {
    font-weight: 700;
    color: rgb(56, 56, 56);
    font-size: 20px;
    margin-bottom: 1em;
  }
  &__text {
    color: rgb(56, 56, 56);
    font-size: 20px;
    margin-bottom: 2em;
  }
  &__link {
    color: rgb(255, 195, 63) !important;
    font-size: 26px;
    font-weight: bold;
  }
}

.burger-btn {
  background: none;
  outline: none !important;
  border: none;
  padding: 0;
  &.active {
    .burger-icon span {
      &:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
.burger-icon {
  width: 28px;
  height: 20px;
  transform: scale(1.4);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999;
  position: relative;

  span {
    background-color: #fd4800;

    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    height: 3px;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 8px;
    }
    &:nth-child(4) {
      top: 16px;
    }
  }
}

.mobile-menu-overlay {
  position: fixed;
  width: 100%;
  height: calc(100% - 87px);
  top: 87px;
  left: 0;
  z-index: 999;
  background: #000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  display: none;
  &.open {
    display: flex;
  }

  .navbar-main {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: auto;
    .nav-item {
      padding: 0;
    }
    .nav-link {
      color: #ffffff;
      font-size: 26px;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: #fd4800;
        opacity: 1 !important;
      }

      &.active {
        color: #fd4800;
        opacity: 0.7;
      }
    }
  }

  .navbar-socials {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: auto;
    margin-bottom: 60px;
    .nav-item {
      padding: 0;
    }
    .nav-link {
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      padding: 0 5px;
    }
  }
}
